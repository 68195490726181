<template>
  <Promised :promise="loadingPromise">
    <template v-slot:pending>
      <ciam-loader class="py-4"></ciam-loader>
    </template>

    <template v-slot:rejected="error">
      <ciam-alert :title="error.message" :description="error.message" :type="AlertStatus.ALERT"></ciam-alert>
    </template>

    <template v-slot:default>
      <ciam-card-content>
        <ciam-card-content-lines>
          <ciam-card-content-line>
            <template v-slot:label>
              <ciam-text>{{ $t('organization.serviceAccounts.create.role') }}</ciam-text>
            </template>
            <template v-slot:content>
              <ciam-select :options="roles" v-model="form.role" @input="emitRoleChange()"></ciam-select>
            </template>
          </ciam-card-content-line>
          <ciam-card-content-line v-if="form.role">
            <template v-slot:label>&nbsp;</template>
            <template v-slot:content>
              <ciam-text>{{ roleDescription }}</ciam-text>
              <ciam-list>
                <ciam-list-item :key="capacity" v-for="capacity in roleCapacities">
                  <template v-slot:left>
                    <ciam-text>
                      {{ capacity }}
                    </ciam-text>
                  </template>
                </ciam-list-item>
              </ciam-list>
            </template>
          </ciam-card-content-line>
        </ciam-card-content-lines>
      </ciam-card-content>
    </template>
  </Promised>
</template>

<script>
import CiamAlert from '@/components/CiamAlert.vue';
import { AlertStatus } from '@/components/CiamAlert.vue';
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamSelect from '@/components/CiamSelect.vue';
import i18n from '@/i18n';
import RoleService from '@/pages/RoleService';
import CiamList from '@/components/CiamList.vue';
import CiamListItem from '@/components/CiamListItem.vue';
import Maybe from 'data.maybe-fgribreau';

export default {
  name: 'ciam-roles',
  components: {
    CiamList,
    CiamListItem,
    CiamSelect,
    CiamAlert,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      loadingPromise: new Promise(() => {
      }),
      problem: null,
      roles: [],
      form: {
        role: null,
      },
    };
  },
  methods: {
    emitRoleChange(event) {
      this.$emit('input', this.form.role);
    }
  },
  mounted() {
    let loader = this.$loading.show();

    this.form.role = '';

    this.loadingPromise = Promise.all([RoleService.getAvailableRoles(), OrganizationService.get(this.organizationId)])
      .then(([roles]) => {
        this.roles = roles;
        // try to find default role ORG_EDITOR and if it don't exist anymore follback on the first roles yield from the API
        this.form.role = Maybe.fromNullable(roles.find((role) => role.value === 'ORG_EDITOR')).getOrElse(
          roles[0],
        ).value;
      })
      .catch((reason) => {
        this.problem = reason;
      })
      .finally(() => loader.hide());
  },
  computed: {
    roleDescription() {
      return i18n.t(`roles.values.${this.form.role}.abstract`);
    },
    /**
     *
     * @returns {String[]}
     */
    roleCapacities() {
      return i18n.t(`roles.values.${this.form.role}.capacities`);
    },
    organizationId() {
      return this.$route.params.id;
    },
  },
};
</script>
