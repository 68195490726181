import { http } from '@/http';
import i18n from '@/i18n';

export default class RoleService {
  /**
   *
   * @returns {Promise<*>}
   */
  static async getAvailableRoles() {
    return http.get(`/roles/`).then((response) =>
      response.data.map((role) => {
        return {
          value: role,
          label: i18n.t(`roles.values.${role}.name`),
        };
      })
    );
  }
}
